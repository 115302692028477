import { SVGProps } from 'react';

function Facebook({
  fill = 'var(--neutral-0)',
  width = 20,
  height = 20,
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_778_21822"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={25}
        height={24}
      >
        <path d="M24.3335 0H0.333496V24H24.3335V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_778_21822)">
        <path
          d="M12.3335 0.0439453C5.70607 0.0439453 0.333496 5.41652 0.333496 12.0439C0.333496 17.6717 4.20783 22.3938 9.43457 23.6906V15.711H6.9601V12.0439H9.43457V10.4638C9.43457 6.37942 11.283 4.48629 15.2928 4.48629C16.0531 4.48629 17.3649 4.63535 17.9015 4.78441V8.10858C17.6183 8.07879 17.1263 8.06388 16.5152 8.06388C14.5475 8.06388 13.7873 8.80922 13.7873 10.7471V12.0439H17.7069L17.0335 15.711H13.7873V23.9561C19.729 23.2384 24.3335 18.1792 24.3335 12.0439C24.3335 5.41652 18.9609 0.0439453 12.3335 0.0439453Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default Facebook;
